import React from 'react';
import TopNavigationMenu from './components/TopNavigationMenu';
import HeroSection from './components/HeroSection';
import About from './components/About';
import Services from './components/Services';
import Activity from './components/Activity';
import Ressources from './components/Ressources';
import { Box } from '@mui/material';
import Testimonials from './components/Testimonials';
import Partners from './components/Partners';
import Contact from './components/Contact';
import Footer from './components/Footer';


function App() {
  return (
    <Box >
      <TopNavigationMenu/>
      <HeroSection/>
      <About/>
      <Services/>
      <Activity/>
      <Ressources/>
      <Testimonials/>
      <Contact/>
      <Footer/>
    </Box>
  );
}

export default App;
