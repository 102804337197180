import React from 'react';
import { Grid, Typography, Button, Card, CardContent, CardActions, Container } from '@mui/material';

const ServicesSection: React.FC = () => {
  return (
    <Container maxWidth='xl'>
    <Grid container spacing={2} sx={{ marginTop: '40px', marginBottom: '40px' }}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Poppins', textAlign:'center' }}>
          Nos Services
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>
              Audit financier
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins', textAlign:'justify' }}>
              Notre équipe d'experts en audit financier fournit des services d'audit approfondis pour évaluer la santé financière de votre entreprise, identifier les risques potentiels et fournir des recommandations pour améliorer la gestion financière.
            </Typography>
          </CardContent>
          <CardActions sx={{ marginTop: 'auto', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" sx={{ width: '50%', borderRadius: '30px', fontFamily: 'Poppins', background: '#008001' }}>
              En savoir plus
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>
              Conseil en auditariat et finance
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins', textAlign:'justify' }}>
              Notre équipe de conseillers en auditariat et finance offre des services de conseil stratégique pour aider les entreprises à optimiser leurs processus financiers, à renforcer leur gouvernance d'entreprise et à atteindre leurs objectifs financiers à long terme.
            </Typography>
          </CardContent>
          <CardActions sx={{ marginTop: 'auto', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" sx={{ width: '50%', borderRadius: '30px', fontFamily: 'Poppins', background: '#008001' }}>
              En savoir plus
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>
              Courtage en assurance
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins', textAlign:'justify' }}>
              Notre service de courtage en assurance propose une gamme complète de solutions d'assurance personnalisées pour répondre aux besoins spécifiques de nos clients. Nous travaillons en étroite collaboration avec les principales compagnies d'assurance pour offrir des options compétitives et adaptées.
            </Typography>
          </CardContent>
          <CardActions sx={{ marginTop: 'auto', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" sx={{ width: '50%', borderRadius: '30px', fontFamily: 'Poppins', background: '#008001' }}>
              En savoir plus
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
    </Container>
  );
};

export default ServicesSection;
