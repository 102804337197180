import React from 'react';
import { Grid, Typography, Link, Container, TextField, Button } from '@mui/material';
import logo from '../assets/images/logo.jpg';
import {  YouTube, Twitter} from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer: React.FC = () => {
  return (
    <footer>
      <section className='footer' style={{ marginTop: '100px', background: '#1D1C3D', width: '100%', color: 'white', fontWeight: 'bold' }}>
        <Container maxWidth='xl' sx={{ height: "100%" }} >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Link><img src={logo} alt='.....' width="140px" /></Link>
              <br />
              <br />
              <Typography>
                <Link href='https://www.linkedin.com/posts/abdou-cisse-363403aa_tribune-a-quand-la-protection-sociale-des-activity-6908137253483499520-pUAc' sx={{ fontFamily: 'Poppins', textDecoration: 'none', color: '#fff', marginRight: '15px' }}><LinkedInIcon sx={{ fontSize: '30px' }} /></Link>
                <Link href='https://youtu.be/0Gdu6AXS6hU' sx={{ fontFamily: 'Poppins', textDecoration: 'none', color: '#fff', marginRight: '15px' }}><YouTube sx={{ fontSize: '30px' }} /></Link>
                <Link href='https://twitter.com/Cisco2nsulting' sx={{ fontFamily: 'Poppins', textDecoration: 'none', color: '#fff' }}><Twitter sx={{ fontSize: '30px', color: '#AE' }} /></Link>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Typography variant='h6' sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>Contact</Typography>
              <br />
              <Typography variant='body1' sx={{ fontFamily: 'Poppins', fontSize: '14px' }}> +221 77 665 31 05 / +33 6 28 88 24 65</Typography>
              <Typography variant='body1' sx={{ fontFamily: 'Poppins', fontSize: '14px' }}>Email :<br />ciscoc@cisafrikconsulting.com</Typography>
              <Typography variant='body1' sx={{ fontFamily: 'Poppins', fontSize: '14px' }}>Localisation : Paris France<br/> Sénegal Dakar</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography variant='h6' sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>NOTRE NEWSLETTER</Typography>
              <br />
              <Typography variant='body1' sx={{ fontFamily: 'Poppins', fontSize: '14px' }}>Abonnez-vous à notre newsletter et recevez toute notre actualité</Typography>
              <br />
              <TextField label='Email ' variant='outlined' sx={{ fontFamily: 'Poppins', width: '100%', color: 'white', border: 'solid white', borderRadius: '8px' }} />
              <br />
              <br />
              <Button variant='contained' className='btn' sx={{ fontFamily: 'Poppins', width: '100%', height: '40px', color: '#fff', background: '#008001', borderRadius: '30px' }}>INSCRIVEZ-VOUS</Button>
              <br/>
              <br/>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
               <Typography variant='h6' sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>Liens d' articles </Typography>
              <Link href='https://www.financialafrik.com/2021/10/22/tribune-zone-cima-quelles-perspectives-apres-cinq-annees-de-tentatives-de-reformes/'><Typography variant='body1' sx={{ fontFamily: 'Poppins' }}>Du rôle de l’assurance dans le développement économique de l’Afrique</Typography></Link>
              <Link href='https://www.financialafrik.com/2021/08/22/synergie-entre-finance-et-assurance-par-la-bancassurance-et-lassurbanque/'> <Typography variant='body1' sx={{ fontFamily: 'Poppins' }}>quelles perspectives après cinq années de tentatives de réformes ?</Typography></Link>
              <Link href='https://www.financialafrik.com/2021/05/13/la-monnaie-encore-au-coeur-de-la-grande-marche-du-nouveau-monde/'><Typography variant='body1' sx={{ fontFamily: 'Poppins' }}>Synergie entre finance et assurance par la Bancassurance et l’Assurbanque</Typography></Link>
              <Link href='https://www.financialafrik.com/2021/01/24/retrospective-2020-la-deesse-de-linflation-est-elle-toujours-vivante/'><Typography variant='body1' sx={{ fontFamily: 'Poppins' }}>Retrospective 2020: la Déesse de l’Inflation est-elle toujours vivante ?</Typography></Link>
              

            </Grid>
          </Grid>
        </Container>
      </section>

      <Typography variant="body2" align="center" sx={{ backgroundColor: '#008001', padding: '10px 0', fontFamily: 'Poppins', color: 'white' }}>
        © {new Date().getFullYear()} Cisco Consulting. Tous droits réservés.
      </Typography>
    </footer>
  );
};

export default Footer;
