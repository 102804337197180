import React from 'react';
import { Grid, Typography, Button, Container } from '@mui/material';
//import Illustration from '../assets/images/Illustration.svg';
import teams2 from '../assets/images/teams2.png';


interface HeroProps {
  // Vous pouvez ajouter des props comme nécessaire
}

const Hero: React.FC<HeroProps> = () => {
  return (
    <Container maxWidth='xl' sx={{ marginTop: '55px', marginBottom: '40px' }}>
    <Grid container spacing={2} >
      {/* Grid pour l'image */}
      <Grid item xs={12} sm={6}>
        {/* Vous pouvez remplacer "image.png" par le chemin de votre propre image */}
        <img src={teams2} alt="Image d'illustration" style={{ width: '100%', height: 'auto' }} />
      </Grid>

      {/* Grid pour le texte */}
      <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
          Audit et Conseil en Finance
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins', lineHeight: '1.5' }}>
 Par une vision claire et propective, Cisco Consulting vous accompagne vers les évolutions des secteurs de l'Assurance  et de la Finance
</Typography>

        <Button variant="contained" color="primary" sx={{width:'30%', borderRadius:'30px', fontFamily:'Poppins', background:'#008001'}}>
          En savoir plus
        </Button>
      </Grid>
    </Grid>
    </Container>
  );
};

export default Hero;
