import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, MenuItem, Menu, Drawer, List, ListItem, ListItemText, Box, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/images/logo.jpg';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

const HeaderAppBar = () => {
  const [servicesAnchorEl, setServicesAnchorEl] = useState<any>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleServicesClick = (event: React.MouseEvent<HTMLLIElement>) => {
    if (event.currentTarget instanceof HTMLElement) {
      setServicesAnchorEl(event.currentTarget);
    }
  };
  

  const handleServicesClose = () => {
    setServicesAnchorEl(null);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
<AppBar position="static" sx={{ background: '#1D1C3D', height: '45px' }}>
      <Toolbar>
        <Grid container spacing={2} alignItems="center">
          {/* Numéro de téléphone */}
          <Grid item xs={6} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <PhoneIcon sx={{ marginRight: 1, marginTop: '-15px' }} />
            <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins', fontSize: '13px', marginTop: '-15px' }}>
              +221 77 665 31 05 / +33 628 88 24 65
            </Typography>
          </Grid>
          {/* Adresse e-mail */}
          <Grid item xs={6} sm={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <MailIcon sx={{ marginRight: 1, marginTop: '-15px' }} />
            <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins', fontSize: '13px', marginTop: '-15px' }}>
              ciscoc@cisafrikconsulting.com
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>



    <AppBar position="static" sx={{ background: '#049C3D' }}>
      <Toolbar>
        {/* Logo de l'entreprise */}
        <img src={logo} alt="Logo de l'entreprise" />
        {/* Ajoutez votre logo ici */}
        {/* Menu principal */}
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" component="div" sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
            <MenuItem sx={{ fontFamily: 'Poppins', fontSize: '18px' }}>Accueil</MenuItem>
            <MenuItem sx={{ fontFamily: 'Poppins', fontSize: '18px' }}>Présentation</MenuItem>
            {/* Services avec sous-menu */}
            <MenuItem sx={{ fontFamily: 'Poppins', fontSize: '18px' }} onClick={handleServicesClick}>Nos Services</MenuItem>
            <Menu
              anchorEl={servicesAnchorEl}
              open={Boolean(servicesAnchorEl)}
              onClose={handleServicesClose}
            >
              <MenuItem sx={{ fontFamily: 'Poppins', fontSize: '18px' }} onClick={handleServicesClose}>Offres de services aux entreprises</MenuItem>
              <MenuItem sx={{ fontFamily: 'Poppins', fontSize: '18px' }} onClick={handleServicesClose}>Offres de services aux assurances</MenuItem>
              <MenuItem sx={{ fontFamily: 'Poppins', fontSize: '18px' }} onClick={handleServicesClose}>Outils de contrôle des assurances</MenuItem>
            </Menu>
            <MenuItem sx={{ fontFamily: 'Poppins', fontSize: '18px' }}>Nous contacter</MenuItem>
          </Typography>
        </Box>
        {/* Icone de menu hamburger pour les petits écrans */}
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          aria-label="menu"
          sx={{ display: { xs: 'flex', md: 'none' } }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        {/* Menu déroulant pour les petits écrans */}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          <List>
            <ListItem button onClick={toggleDrawer(false)}>
              <ListItemText primary="Accueil" />
            </ListItem>
            <ListItem button onClick={toggleDrawer(false)}>
              <ListItemText primary="Présentation" />
            </ListItem>
            <ListItem button onClick={toggleDrawer(false)}>
              <ListItemText primary="Nos Services" />
            </ListItem>
            <ListItem button onClick={toggleDrawer(false)}>
              <ListItemText primary="Offres de services aux entreprises" />
            </ListItem>
            <ListItem button onClick={toggleDrawer(false)}>
              <ListItemText primary="Offres de services aux assurances" />
            </ListItem>
            <ListItem button onClick={toggleDrawer(false)}>
              <ListItemText primary="Outils de contrôle des assurances" />
            </ListItem>
            <ListItem button onClick={toggleDrawer(false)}>
              <ListItemText primary="Nous contacter" />
            </ListItem>
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
    </>
  );
};

export default HeaderAppBar;
