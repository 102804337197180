import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import pdg from '../assets/images/untitled3.jpg';

const AboutSection: React.FC = () => {
  return (
    <Container maxWidth='xl'>
    <Grid container spacing={2} sx={{ marginTop: '40px', marginBottom: '40px' }}>
    <Grid item xs={12}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Poppins', textAlign:'center' }}>
        À propos de notre entreprise
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
       
        <Typography variant="body1" paragraph sx={{ fontFamily:'Poppins', textAlign:'justify'}}>
          L'entreprise <span style={{color:'#008001', fontFamily:'Poppins', fontWeight:'bold', fontSize:'20px'}}> Cisco Consulting</span> est une société spécialisée dans l'audit, le conseil en auditariat et finance, ainsi que le courtage en assurance. Depuis notre création, nous nous engageons à fournir à nos clients des solutions financières innovantes et sur mesure pour répondre à leurs besoins les plus complexes.
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontFamily:'Poppins', textAlign:'justify'}}>
          Notre entreprise a été fondée sur des principes de transparence, d'intégrité et d'excellence. Nous croyons fermement que ces valeurs sont essentielles pour établir des relations solides avec nos clients et assurer leur succès à long terme.
        </Typography>
        <Typography variant="body1" sx={{ fontFamily:'Poppins', textAlign:'justify'}}>
          Notre équipe est composée d'experts qualifiés dans divers domaines de la finance, de l'audit et de l'assurance. Ensemble, nous mettons notre expertise et notre expérience au service de nos clients pour les aider à atteindre leurs objectifs financiers et stratégiques.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {/* Vous pouvez ajouter une image ou une vidéo représentant votre entreprise */}
        <img
          src={pdg}
          alt="À propos de notre entreprise"
          style={{ width: '100%', objectFit:'cover'}}
        />
      </Grid>
    </Grid>
    </Container>
  );
};

export default AboutSection;
