import React from 'react';
import { Container, Grid, Typography, Card, CardContent, List, ListItem, ListItemText, Link } from '@mui/material';

const ResourcesSection: React.FC = () => {
  return (
    <Container maxWidth="xl" sx={{ marginTop: '40px', marginBottom: '40px' }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontFamily: 'Poppins' }}>
        Ressources
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>
                Articles de blog :
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary="5 Stratégies pour Améliorer la Gestion Financière" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Les Tendances Actuelles dans le Domaine de l'Audit" />
                </ListItem>
                {/* Ajoutez d'autres articles de blog si nécessaire */}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>
                Guides et Livres Blancs :
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary="Guide Pratique pour l'Audit Financier" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Livret Blanc : Optimisation des Processus d'Assurance" />
                </ListItem>
                {/* Ajoutez d'autres guides et livres blancs si nécessaire */}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>
                Ressources Externes Utiles :
              </Typography>
              <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins' }}>
                Voici quelques liens vers des ressources externes qui pourraient être utiles :
              </Typography>
              <List>
                <ListItem>
                  <Link href="https://www.iaasb.org/" target="_blank" rel="noopener" sx={{ fontFamily: 'Poppins' }}>
                    International Auditing and Assurance Standards Board
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="https://www.iii.org/" target="_blank" rel="noopener" sx={{ fontFamily: 'Poppins' }}>
                    Insurance Information Institute
                  </Link>
                </ListItem>
                {/* Ajoutez d'autres liens vers des ressources externes si nécessaire */}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResourcesSection;
