import React from 'react';
import { Container, Grid, Typography, Card, CardContent, List, ListItem, ListItemText } from '@mui/material';

const IndustriesSection: React.FC = () => {
  return (
    <Container maxWidth="xl" sx={{ marginTop: '40px', marginBottom: '40px' }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontFamily: 'Poppins' }}>
        Secteurs d'activité
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>
                Secteurs dans lesquels nous opérons :
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary="Finance" sx={{ fontFamily: 'Poppins' }} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Assurance" sx={{ fontFamily: 'Poppins' }} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Secteur public" sx={{ fontFamily: 'Poppins' }} />
                </ListItem>
                {/* Ajoutez d'autres secteurs si nécessaire */}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>
                Expertise spécifique dans chaque secteur :
              </Typography>
              <List>
                <ListItem >
                  <ListItemText
                    primary="Finance"
                    secondary="Audit financier, Conseil en investissement, Gestion de patrimoine"
                    sx={{ fontFamily: 'Poppins' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Assurance"
                    secondary="Courtage en assurance, Évaluation des risques, Gestion des sinistres"
                    sx={{ fontFamily: 'Poppins' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Secteur public"
                    secondary="Audit gouvernemental, Consultation en politique publique"
                    sx={{ fontFamily: 'Poppins' }}
                  />
                </ListItem>
                {/* Ajoutez d'autres expertises spécifiques si nécessaire */}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IndustriesSection;
