import React from 'react';
import { Grid, Typography, Card, CardContent, Container } from '@mui/material';

const Testimonials: React.FC = () => {
  return (
    <Container maxWidth='xl'>
    <Grid container spacing={2} sx={{ marginTop: '40px', marginBottom: '40px' }}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom align='center' sx={{ fontFamily: 'Poppins' }}>
          Témoignages clients
        </Typography>
      </Grid>
      {/* Témoignage 1 */}
      <Grid item xs={12} sm={4}>
        <Card sx={{ height: '100%' }}>
          <CardContent>
            <Typography variant="body1" align="justify" paragraph sx={{ fontFamily: 'Poppins' }}>
              "Travailler avec CisCo Consulting a été une expérience exceptionnelle. Leur équipe professionnelle et compétente a su répondre à tous nos besoins en matière d'audit et de conseil financier. Je les recommande vivement."
            </Typography>
            <Typography variant="body2" align="center" sx={{ fontFamily: 'Poppins' }}>
              - Jean Dupont, CEO de ABC Company
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {/* Témoignage 2 */}
      <Grid item xs={12} sm={4}>
        <Card sx={{ height: '100%' }}>
          <CardContent>
            <Typography variant="body1" align="justify" paragraph sx={{ fontFamily: 'Poppins' }}>
              "Nous avons fait appel à CisCo Consulting pour nous aider à optimiser nos processus d'assurance. Leurs conseils ont été précieux et ont considérablement amélioré notre efficacité opérationnelle."
            </Typography>
            <Typography variant="body2" align="center" sx={{ fontFamily: 'Poppins' }}>
              - Marie Martin, Directrice des opérations de DEF Corporation
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {/* Témoignage 3 */}
      <Grid item xs={12} sm={4}>
        <Card sx={{ height: '100%' }}>
          <CardContent>
          <Typography variant="body1" align="justify" paragraph sx={{ fontFamily: 'Poppins' }}>
  Merci pour votre soutien continu et votre engagement envers notre entreprise. Votre confiance est notre plus grande motivation.
</Typography>
<Typography variant="body1" align="justify" paragraph sx={{ fontFamily: 'Poppins' }}>
  Nous sommes impatients de poursuivre notre collaboration fructueuse et de vous offrir des solutions innovantes pour répondre à vos besoins.
</Typography>
<Typography variant="body2" align="center" sx={{ fontFamily: 'Poppins' }}>
  - Jane Claire, Directrice des relations clients
</Typography>

          </CardContent>
        </Card>
      </Grid>
      {/* Ajoutez d'autres témoignages si nécessaire */}
    </Grid>
    </Container>
  );
};

export default Testimonials;
