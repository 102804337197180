import { Container, Grid, Typography, TextField, Button,  Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Définir les styles personnalisés
const useStyles = makeStyles((theme: Theme) => ({
 
  button: {
    background: '#008001', // Couleur d'arrière-plan personnalisée
    color: '#FFFFFF', // Couleur du texte personnalisée
    '&:hover': {
      background: '#006400', // Couleur d'arrière-plan au survol personnalisée
    },
  },
}));

const Contact = () => {
  const classes = useStyles();

  return (
    <Container maxWidth='xl'>
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <Typography variant="h4" align='center' gutterBottom>
            Contact
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Nom"
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            margin="normal"
          />
          <Button variant="contained" className={classes.button} sx={{fontFamily:'Poppins', background:'#008001', width:'40%'}}>
            Envoyer
          </Button>
        </Grid>
    
  
  <Grid item xs={12} md={6} mt='15px' >
    <Typography variant="h6" gutterBottom fontFamily='Poppins'>
      Dakar
    </Typography>
    <Typography variant="body1" paragraph fontFamily='Poppins'>
      Rue 2 villa 9B-point E, Ville, Pays
    </Typography>
    <Typography variant="body1" paragraph fontFamily='Poppins'>
    +221 77 665 31 05
    </Typography>
    <Typography variant="body1">
      +221 33 825 26 00
    </Typography>
    <Typography variant="h6" gutterBottom fontFamily='Poppins'>
      Paris
    </Typography>
    <Typography variant="body1" paragraph fontFamily='Poppins'> 
      3, Rue Redeon 75 017 Paris
    </Typography>
    <Typography variant="body1" paragraph fontFamily='Poppins'>
      +33 6 28 88 24 65
    </Typography>
    <Typography variant="body1" fontFamily='Poppins'>
      +33 6 06 54 59 37
    </Typography>
  </Grid>
</Grid>

   
    </Container>
  );
};

export default Contact;
